import React from "react";
import logo from "images/logos/Primary Logo.png";
import { IHotelDetails } from "interfaces/hotel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

const Footer: React.FC<{ hotelDetails: IHotelDetails }> = ({
  hotelDetails,
}) => {

  // Split by comma and trim each email, then join them with ', '
  const emailList = hotelDetails.emails.split(",").map(email => email.trim());

  // Handler for email click
  const handleEmailClick = (email: string) => {
    window.open(`mailto:${email}`, "_blank");
  };

  return (
    <footer className="bg-secondary-gray pt-8 pb-8 mt-20 text-gray-800">
      <div className="container mx-auto lg:px-0">
        <div className="flex flex-wrap justify-between">
          {/* Logo and Company Info */}
          <div className="w-full lg:w-1/3 mb-10 lg:mb-0">
            <img
              src={hotelDetails.logo}
              alt="Site Logo"
              className="h-12 mb-4"
            />
            <div className="text-base">
              <div>
                Réservation en ligne sécurisée propulsée par
                <span className="ml-2">
                  <a
                    href="https://kinkobooking.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={logo}
                      alt="Site Logo"
                      className="h-3 mb-1 inline-block"
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>

          {/* Quick Links */}
          <div className="w-full lg:w-1/4 mb-10 lg:mb-0">
            <h3 className="font-semibold text-base mb-4">Liens Rapides</h3>
            <ul className="space-y-4 text-sm">
              <li>
                <a href="#rooms_available" className="hover:text-primary-blue">
                  Chambres
                </a>
              </li>
              <li>
                <a
                  href="#services_equipment"
                  className="hover:text-primary-blue"
                >
                  Services et équipements
                </a>
              </li>
              <li>
                <a href="#accommodation" className="hover:text-primary-blue">
                  Hébergement
                </a>
              </li>
              <li>
                <a href="#general_infos" className="hover:text-primary-blue">
                  Informations générales
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="w-full lg:w-1/3 mb-10 lg:mb-0">
            <h3 className="font-semibold text-base mb-4">Contactez-Nous</h3>
            <ul className="space-y-4 text-sm">
              <li className="space-x-2">
                <FontAwesomeIcon icon={faEnvelope} />
                {emailList.map((email, index) => (
                  <React.Fragment key={index}>
                    <a
                      href="#"
                      onClick={() => handleEmailClick(email)}
                      className="hover:text-red-600"
                    >
                      {email}
                    </a>
                    {index < emailList.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </li>

              <li className="space-x-2">
                <FontAwesomeIcon icon={faMapPin} />
                <span>
                  {hotelDetails.name}, {hotelDetails.address},{" "}
                  {hotelDetails.city.city}, Tunisie
                </span>
              </li>
              <li className="space-x-2">
                <FontAwesomeIcon icon={faPhone} />
                <span>{hotelDetails.phones}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
