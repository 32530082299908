import { FC, useEffect, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link, useHistory } from "react-router-dom";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import StayCardPricesTable, {
  ISelectedArrangements,
} from "components/StayCardPricesTable/StayCardPricesTable";
import moment from "moment";
import { Transition } from "@headlessui/react";
import { ISearchResult } from "interfaces/searchResult";
import { useSearch } from "context/SearchContext";
import { getHotelHighestPromotion } from "utils/prices.utils";
import { postInitBookingService } from "api.service";
import { IPostInitBookingServiceParams } from "interfaces/booking";

export interface StayCardHProps {
  className?: string;
  hotel?: ISearchResult;
  isHotelDetails?: boolean;
}

const StayCardH: FC<StayCardHProps> = ({
  className = "",
  hotel,
  isHotelDetails,
}) => {
  const [isPricesDetailsOpen, setIsPricesDetailsOpen] = useState(false);
  const { searchData, getSearchPathWithQuery, userType } = useSearch();
  const [selectedArrangements, setSelectedArrangements] =
    useState<ISelectedArrangements>({});
  const [selectedRooms, setSelectedRooms] = useState<{
    [roomIndex: number]: number /* number = roomTypeId */;
  }>({});
  const promo = getHotelHighestPromotion(hotel?.promotions);
  const [initBookingLoading, setInitBookingLoading] = useState(false);
  const history = useHistory();

  const handleInitBooking = async () => {
    setInitBookingLoading(true);
    const reservation: IPostInitBookingServiceParams = {
      hotel: hotel?.data?.slug!,
      start_date: searchData.dateRange.startDate?.format("YYYY-MM-DD") || "",
      end_date: searchData.dateRange.endDate?.format("YYYY-MM-DD") || "",
      currency: searchData.currency,
      occupancies: searchData.rooms.reduce((acc: any, val, index) => {
        let occupancy = {
          adult: val.guestAdults,
          child: String(val.guestChildren.length),
          child_ages: val.guestChildren,
          room_id: String(selectedRooms[index]),
          board_id: String(
            selectedArrangements[index][selectedRooms[index]].boardId
          ),
        };

        acc.push(occupancy);
        return acc;
      }, []),
    };
    try {
      const res = await postInitBookingService(reservation);
      history.push("/checkout/" + "?checkoutSlug=" + res.slug);
    } catch (error) {}
    setInitBookingLoading(false);
  };
  useEffect(() => {
    const data = hotel?.occupancies || [];
    if (data.length !== 0) {
      const rooms = data?.reduce((acc, val, index) => {
        if (!val.room_types[0]?.stop_sale) {
          acc = {
            ...acc,
            [index]: val.room_types[0].data.id,
          };
        }
        return acc;
      }, {});
      setSelectedRooms(rooms);
      const arrangements = data.reduce((acc, val, index) => {
        if (!val.room_types[0].stop_sale) {
          acc = {
            ...acc,
            [index]: val.room_types.reduce((acc, roomType) => {
              acc = {
                ...acc,
                [roomType.data.id]: {
                  boardId: roomType.board[0].data.id,
                },
              };
              return acc;
            }, {}),
          };
        }
        return acc;
      }, {});
      setSelectedArrangements(arrangements);
    }
  }, [hotel]);

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full md:w-72 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={hotel?.data?.cover ? [hotel?.data?.cover] : []}
          uniqueID={`StayCardH_${hotel?.data?.slug}`}
          {...(!isHotelDetails
            ? {
                href: getSearchPathWithQuery(
                  "/hotel-detail/" + hotel?.data?.slug,
                  true
                ),
              }
            : {})}
        />
        {promo && (
          <SaleOffBadge
            desc={`${promo.name} - ${promo.percent}%`}
            className="absolute left-3 top-3 font-bold "
          />
        )}
      </div>
    );
  };

  const minAverageRoomPrice = hotel?.occupancies?.reduce(
    (acc, val) => {
      val.room_types.forEach((room) => {
        room.board.forEach((arrangement) => {
          if (userType === "Hotel") {
            if (acc.price === 0)
              return (acc = {
                price: Number(arrangement.net),
                withoutPromotionPrice: Number(arrangement.rate),
              });
            if (Number(arrangement.rate) < acc.price) {
              return (acc = {
                price: Number(arrangement.net),
                withoutPromotionPrice: Number(arrangement.rate),
              });
            }
          }
          if (userType === "Agency") {
            if (acc.price === 0)
              return (acc = {
                price: Number(arrangement.net),
                withoutPromotionPrice: Number(arrangement.rate),
              });
            if (Number(arrangement.rate) < acc.price) {
              return (acc = {
                price: Number(arrangement.net),
                withoutPromotionPrice: Number(arrangement.rate),
              });
            }
          }
        });
      });
      return acc;
    },
    { price: 0, withoutPromotionPrice: 0 }
  );

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:p-5 flex flex-col">
        <div className="space-y-2">
          <div className="flex justify-between">
            <div className="flex flex-wrap items-center">
              <h2
                className={`${
                  isHotelDetails ? "lg:text-3xl  lg:mb-2 " : ""
                } text-primary-blue font-bold capitalize`}
              >
                <div className=" flex flex-wrap">
                  <div className="mr-2">{hotel?.data?.name}</div>
                  {
                    <StartRating
                      starClassName="lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]"
                      reviewCount={0}
                      point={hotel?.data?.stars}
                    />
                  }
                </div>
                <div className="text-sm font-normal">
                  {hotel?.data.hotel_type}
                </div>
              </h2>
            </div>
            {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}

            {minAverageRoomPrice?.price && (
              <span className="text-xl font-semibold text-primary-blue">
                <div className="flex items-end justify-end flex-wrap">
                  <span
                    className={`${
                      isHotelDetails ? "text-sm" : "text-xs"
                    } mr-1 font-normal text-primary-blue`}
                  >
                    dès
                  </span>
                  {minAverageRoomPrice?.withoutPromotionPrice !==
                    minAverageRoomPrice?.price && (
                    <div className="text-sm font-normal mr-1 line-through text-red-600 ">
                      {minAverageRoomPrice?.withoutPromotionPrice}
                      <span className=" ml-1">{searchData?.currency}</span>
                    </div>
                  )}
                  <div
                    className={`${
                      isHotelDetails ? "lg:text-3xl" : "text-base"
                    }`}
                  >
                    <span className="top-[2px] relative">
                      {minAverageRoomPrice?.price}
                    </span>
                    <span className="text-base ml-1">
                      {searchData?.currency}
                    </span>
                  </div>
                </div>
              </span>
            )}
          </div>
          {isHotelDetails && promo ? (
            <SaleOffBadge
              desc={`${promo.name} - ${promo.percent}%`}
              className="w-fit font-bold "
            />
          ) : null}
          {hotel?.rules?.length ? (
            <div className="text-sm font-semibold text-primary-blue dark:text-neutral-400">
              <span>
                <i className="las la-info-circle  text-lg"></i>{" "}
                {hotel?.rules.map((rule, i) => (
                  <span key={i}>
                    {rule.title}
                    {rule.url && (
                      <a href={rule.url} target={"_blank"} rel="noreferrer">
                        <i className="las la-link ml-1"></i>
                      </a>
                    )}
                    {hotel?.rules.length - 1 !== i && ", "}
                  </span>
                ))}
              </span>
            </div>
          ) : null}
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              <i className="las la-map-signs text-lg"></i> {hotel?.data?.city.city}
            </span>
          </div>
          <div className="text-sm text-neutral-500 dark:text-neutral-400">
            <span>
              <i className="las la-map-marker text-lg"></i>{" "}
              {hotel?.data?.address}
            </span>
          </div>
        </div>

        {!isHotelDetails && (
          <div className="text-sm text-neutral-500 dark:text-neutral-400 mt-2 h-full">
            <span>{hotel?.data?.description.slice(0, 200)}...</span>
          </div>
        )}
        {/* {renderTienIch()} */}
        <div
          className={`flex justify-between items-end ${
            isHotelDetails ? "mt-2" : ""
          } `}
        >
          <div className="text-green-600 text-sm flex items-center">
            {hotel?.is_hotel_stop_sales && (
              <>
                <div className=" text-red-600 ">
                  <i className="lar la-sad-tear text-2xl"></i>
                </div>
                <div className="ml-1 text-red-600 font-semibold">
                  Stop Sales
                </div>
              </>
            )}
            {!hotel?.is_hotel_stop_sales &&
              hotel?.cancellation_deadline?.is_free_cancellation && (
                // &&
                // moment().isSameOrBefore(moment(searchData.dateRange.startDate).subtract(hotel?.cancellation_deadline.free_cancellation_days, "day"), "days") &&
                <>
                  <div className="">
                    <i className="lar la-check-circle text-2xl"></i>
                  </div>
                  <div className="ml-1 font-semibold">
                    Annulation gratuite{" "}
                    {hotel?.cancellation_deadline?.until &&
                      "jusqu'au " +
                        moment(
                          hotel?.cancellation_deadline.until,
                          "DD-MM-YYYY"
                        ).format("DD-MM-YYYY")}
                    {/* {hotel?.cancellation_deadline?.cancellation_type ===
                      "free_and_penalty_cancellation" &&
                      "jusqu'au " +
                        moment(searchData.dateRange.startDate)
                          .subtract(
                            hotel?.cancellation_deadline.free_cancellation_days,
                            "day"
                          )
                          .format("DD-MM-YYYY")} */}
                  </div>
                </>
              )}
          </div>
          {!isHotelDetails && (
            <ButtonPrimary onClick={() => setIsPricesDetailsOpen((v) => !v)}>
              <span className="text-xs capitalize">chambres & tarifs</span>
            </ButtonPrimary>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-primary-gray rounded-2xl overflow-hidden ${
        !isHotelDetails ? "hover:shadow-xl" : ""
      } transition-shadow will-change-transform ${className}`}
      data-nc-id="StayCardH"
    >
      {!isHotelDetails && (
        <Link
          to={getSearchPathWithQuery(
            "/hotel-detail/" + hotel?.data?.slug,
            true
          )}
          className="absolute inset-0"
        ></Link>
      )}
      <div className="grid grid-cols-1 md:flex md:flex-row ">
        {!isHotelDetails && renderSliderGallery()}
        {renderContent()}
      </div>
      <Transition
        show={isPricesDetailsOpen}
        enter="transition ease-out duration-1000"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <div className="pt-4 w-full">
          <StayCardPricesTable
            initBookingLoading={initBookingLoading}
            handleInitBooking={handleInitBooking}
            selectedArrangements={selectedArrangements}
            setSelectedArrangements={setSelectedArrangements}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            data={hotel?.occupancies || []}
            hotelSlug={hotel?.data?.slug}
            className=""
          />
        </div>
      </Transition>
    </div>
  );
};

export default StayCardH;
