import { FC, useEffect, useMemo, useRef, useState } from "react";
import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { addLetterSofPlural } from "utils/string";
import { useSearchParam } from "react-use";
import { calculateStayTotalPrice } from "utils/prices.utils";
import { postInitBookingService, postSearchHotelsService } from "api.service";
import { useHistory, useParams } from "react-router-dom";
import { useFetchHotelDetails } from "hooks/useFetchHotelDetails";
import { useFetch } from "hooks/useFetch";
import { HotelSetting } from "interfaces/hotel";
import { DisplaySideBarBody } from "./DisplaySideBarBody";
import GuestsInput, {
  DEFAULT_ROOM_DATA,
} from "components/HeroSearchForm/GuestsInput";
import { useSearch } from "context/SearchContext";
import { useSearchResultFn } from "hooks/useSearchApi";
import { ISearchResult } from "interfaces/searchResult";
import { currentUrlParams } from "utils/getUrlParams";
import { IPostInitBookingServiceParams } from "interfaces/booking";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import MobileFooterSticky from "./MobileFooterSticky";
import Header3 from "components/Header/Header3";
import BookingRooms from "./BookingRooms";
import StayCardH from "components/StayCardH/StayCardH";
import Preloader from "components/Preloader";
import Page404 from "../Page404/Page404";
import SeeMoreText from "components/SeeMoreText";
import IconAndText from "components/IconAndText";
import RoomCard from "./RoomCard";
import Footer from "shared/Footer/Footer";
import BannerImagesIcon from "data/icons/BannerImagesIcon";
import moment from "moment";
import NcDropDown from "shared/NcDropDown/NcDropDown";
import { DefaultHeader } from "components/Header/DefaultHeader";

export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const hotelService = useSearchResultFn(postSearchHotelsService);
  const initBookingService = useSearchResultFn(postInitBookingService);
  const { hotelDetails, hotelDetailsLoading } = useFetchHotelDetails();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isSeeAllEquipements, setIsSeeAllEquipements] = useState(false);
  const [selectedArrangements, setSelectedArrangements] =
    useState<ISelectedArrangements>({});
  const [selectedRooms, setSelectedRooms] = useState<{
    [roomIndex: number]: number /* number = roomTypeId */;
  }>({});
  const [hotelSetting] = useFetch<HotelSetting>("hotel_settings");
  const { hotelSlug }: { hotelSlug: string } = useParams();
  const {
    searchData,
    searchDestinations,
    getSearchPathWithQuery,
    myHotelInformation,
    setSearchData,
    userType,
    searchResult,
    setSearchResult,
    handleSearch,
  } = useSearch();
  const destinationId = useSearchParam("location");
  const history = useHistory();

  const isOverBooked = useMemo(() => {
    return searchResult?.occupancies.reduce(
      (acc: any, val: any, index: any) => {
        const room = val.room_types.find(
          (el: any) => el.data.id === selectedRooms[index]
        );
        if (acc) return acc;
        if (!room) return true;
        if (!room.immediate_booking) return true;
        if (room.rate_type === "onrequest") return true;
        if (room?.quantity === 0 || room?.stop_sale) return true;
        return false;
      },
      false
    );
  }, [selectedRooms]);

  const isStopStale = useMemo(() => {
    return searchResult?.occupancies.reduce(
      (acc: any, val: any, index: any) => {
        const room = val.room_types.find(
          (el: any) => el.data.id === selectedRooms[index]
        );
        if (acc) return acc;
        if (!room) return true;
        if (room.rate_type === "stop_sale") return true;
        if (room?.stop_sale) return true;
        return false;
      },
      false
    );
  }, [selectedRooms]);

  useEffect(() => {
    if (searchResult?.occupancies.length !== 0) {
      const rooms = searchResult?.occupancies.reduce(
        (acc: any, val: any, index: any) => {
          const sortedByPrice = val.room_types.sort(
            (a: any, b: any) =>
              Number(a?.board?.[0].net) - Number(b?.board?.[0].net)
          );
          const firstSelectableRoomForSales = sortedByPrice.find(
            (el: any) => !el.stop_sale && el.quantity
          );
          const firstSelectableRoomOverbooked = sortedByPrice.find(
            (el: any) => !el.stop_sale && !el.quantity
          );
          const firstSelectableRoomStopsales = sortedByPrice.find(
            (el: any) => el.stop_sale
          );
          acc = {
            ...acc,
            [index]:
              firstSelectableRoomForSales?.data.id ||
              firstSelectableRoomOverbooked?.data.id ||
              firstSelectableRoomStopsales?.data.id,
          };

          return acc;
        },
        {}
      );
      setSelectedRooms(rooms);

      const arrangements = searchResult?.occupancies.reduce(
        (acc: any, val: any, index: any) => {
          acc = {
            ...acc,
            [index]: val.room_types.reduce((acc: any, roomType: any) => {
              acc = {
                ...acc,
                [roomType.data.id]: {
                  boardId: roomType.board[0].data.id,
                },
              };
              return acc;
            }, {}),
          };
          return acc;
        },
        {}
      );
      setSelectedArrangements(arrangements);
    }
  }, [searchResult]);

  useEffect(() => {
    const currentParams = currentUrlParams();
    if (currentParams.start) {
      const start_date = moment(currentParams.start);
      const end_date = moment(currentParams.end);
      const rooms = Array(Number(currentParams.rooms)).fill(DEFAULT_ROOM_DATA);

      setSearchData((prev: any) => {
        return {
          ...prev,
          dateRange: { startDate: start_date, endDate: end_date },
          rooms,
        };
      });

      const occupancies = rooms.map((room) => ({
        adult: room.guestAdults,
        child: room.guestChildren.length,
        child_ages: room.guestChildren,
      }));

      hotelService
        .execute({
          hotel: hotelSlug,
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD"),
          currency: searchData?.currency,
          occupancies,
        })
        .then((res: [ISearchResult]) => {
          setSearchResult(res[0]);
          setTimeout(() => {
            document
              .getElementById("search-room-result")
              ?.scrollIntoView({ behavior: "smooth" });
          }, 100);
        });
    }
  }, []);

  const handleInitBooking = async () => {
    const reservation: IPostInitBookingServiceParams = {
      hotel: hotelSlug,
      start_date: searchData.dateRange.startDate?.format("YYYY-MM-DD") || "",
      end_date: searchData.dateRange.endDate?.format("YYYY-MM-DD") || "",
      currency: searchData.currency,
      occupancies: searchData.rooms.reduce((acc: any, val, index) => {
        const occupancy = {
          adult: val.guestAdults,
          child: String(val.guestChildren.length),
          child_ages: val.guestChildren,
          room_id: String(selectedRooms[index]),
          board_id: String(
            selectedArrangements[index][selectedRooms[index]].boardId
          ),
        };

        acc.push(occupancy);
        return acc;
      }, []),
    };

    initBookingService.execute(reservation).then((res: any) => {
      history.push("/checkout?checkoutSlug=" + res.slug);
    });
  };

  const onSelectRoomType = (roomIndex: number) => (roomTypeId: number) => {
    setSelectedRooms((v) => ({
      ...v,
      [roomIndex]: roomTypeId,
    }));
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const getSearchLink = () => {
    if (
      searchDestinations.hotels.find((el) => el.slug === searchData.hotel_slug)
    ) {
      return getSearchPathWithQuery("/hotel-detail/" + searchData.hotel_slug);
    } else if (myHotelInformation?.slug) {
      return getSearchPathWithQuery(
        "/hotel-detail/" + myHotelInformation?.slug
      );
    } else {
      return getSearchPathWithQuery("/hotels");
    }
  };

  const calculateTotalPrice = () => {
    // debugger;
    return calculateStayTotalPrice(
      userType!,
      searchResult?.occupancies!,
      selectedRooms,
      selectedArrangements
    );
  };

  const handleCloseModal = () => setIsOpen(false);

  const handleSearchClick = () => {
    handleSearch().then(() => {
      document
        .getElementById("search-room-result")
        ?.scrollIntoView({ behavior: "smooth" });
      window.history.replaceState(null, "Maison Kinko", getSearchLink());
    });
  };

  const renderSection2 = () => {
    return (
      <section id="accommodation" className="listingSection__wrap">
        <h2 className="lg:text-lg sm:text-sm text-sm text-primary-blue font-bold capitalize ">
          Hébergement
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className=" text-neutral-black dark:text-neutral-300 ">
          <span className="text-sm lg:text-base whitespace-pre-wrap">
            <SeeMoreText text={hotelDetails?.description || ""} />
          </span>
        </div>
      </section>
    );
  };

  const renderSection3 = () => {
    return (
      <div id="services_equipment" className="listingSection__wrap">
        <div>
          <h2 className="lg:text-lg sm:text-sm text-sm text-primary-blue font-bold capitalize">
            Services & équipements
          </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-black dark:text-neutral-300 ">
          {hotelDetails?.amenities
            .slice(0, !isSeeAllEquipements ? 6 : undefined)
            ?.map((el: any) => (
              <IconAndText name={el.name} icon={el.la_icon} key={el.name} />
            ))}
        </div>
        <div
          className="text-xs cursor-pointer underline text-black mt-3"
          onClick={() => setIsSeeAllEquipements((v) => !v)}
        >
          {isSeeAllEquipements ? "< Reduire" : "En savoir plus >"}
        </div>
      </div>
    );
  };

  const renderSection4 = () => {
    return (
      <section id="general_infos" className="listingSection__wrap">
        <h2 className="lg:text-lg sm:text-sm text-sm text-primary-blue font-bold capitalize ">
          Informations Générales
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400  text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 text-neutral-black bg-neutral-100 dark:bg-primary-gray rounded-lg">
              <span>Email</span>
              <span>{hotelDetails?.emails}</span>
            </div>
            <div className="flex space-x-10 text-neutral-black justify-between p-3">
              <span>Téléphone</span>
              <span>{hotelDetails?.phones}</span>
            </div>
            <div className="flex space-x-10 justify-between p-3 text-neutral-black bg-neutral-100 dark:bg-primary-gray rounded-lg">
              <span>Minimum âge enfant</span>
              <span>
                {hotelDetails?.min_child_age
                  ? hotelDetails?.min_child_age +
                    " an" +
                    addLetterSofPlural(hotelDetails?.min_child_age)
                  : "-"}
              </span>
            </div>
            <div className="flex space-x-10  text-neutral-black  justify-between p-3">
              <span>Maximum âge enfant</span>
              <span>
                {hotelDetails?.max_child_age
                  ? hotelDetails?.max_child_age +
                    " an" +
                    addLetterSofPlural(hotelDetails?.max_child_age)
                  : "-"}
              </span>
            </div>
            <div className="flex space-x-10 justify-between p-3 text-neutral-black bg-neutral-100 dark:bg-primary-gray rounded-lg">
              <span>Check-In</span>
              <span>{hotelDetails?.checkin}</span>
            </div>
            <div className="flex space-x-10 text-neutral-black justify-between p-3">
              <span>Check-Out</span>
              <span>{hotelDetails?.checkout}</span>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderHotelRoomsSection = () => {
    return (
      <section id="rooms_available" className="listingSection__wrap">
        <h2 className="lg:text-lg sm:text-sm text-sm text-primary-blue font-bold capitalize ">
          Les Chambres
        </h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
            {hotelDetails?.room_types?.map((el: any, index: number) => (
              <div className="" key={index}>
                <RoomCard
                  roomIndex={index}
                  hotelDetails={hotelDetails}
                  isHotelDetails
                  isStopSales={false}
                  setSelectedArrangements={setSelectedArrangements}
                  selectedArrangements={selectedArrangements}
                  className="mb-4"
                  badgeDisplay={false}
                  room={{
                    ...el,
                    board: [],
                    data: { id: el.id, name: el.name },
                    stop_sale: false,
                    rate_type: el.rate_type,
                    immediate_booking: true,
                    quantity: 1,
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  const renderSidebar = () => {
    const renderTrigger = () => {
      return (
        <div className="text-start px-8 h-full w-full  py-3">
          <span className="block font-semibold">Devise</span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {searchData.currency}
          </span>
        </div>
      );
    };

    const renderItem = (item: { id: string; name: string; icon: string }) => {
      return (
        <div
          className="py-2 px-4 hover:bg-gray-100 rounded-md"
          onClick={() =>
            setSearchData((prev: any) => ({ ...prev, currency: item.name }))
          }
        >
          {item.name}
        </div>
      );
    };

    return (
      <div className="listingSectionSidebar__wrap  shadow-xl">
        <>
          <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl text-black ">
            <StayDatesRangeInput
              defaultValue={searchData.dateRange}
              onChange={(e) => {
                setSearchData({
                  ...searchData,
                  dateRange: e,
                });
              }}
              wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
              fieldClassName="p-3"
              anchorDirection={"right"}
              className="nc-ListingStayDetailPage__stayDatesRangeInput flex-1"
            />
            <GuestsInput
              className="nc-ListingStayDetailPage__guestsInput flex-1 border-y"
              fieldClassName="p-3"
              rooms={searchData.rooms}
              onChange={(e) => {
                setSearchData({
                  ...searchData,
                  rooms: e,
                });
              }}
              defaultValue={{}}
              hasButtonSubmit={false}
            />
            <div className="flex items-center">
              <div className="relative left-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 640 512"
                  fill="#999"
                  className=""
                >
                  <path d="M352 288h-16v-88c0-4.42-3.58-8-8-8h-13.58c-4.74 0-9.37 1.4-13.31 4.03l-15.33 10.22a7.994 7.994 0 0 0-2.22 11.09l8.88 13.31a7.994 7.994 0 0 0 11.09 2.22l.47-.31V288h-16c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h64c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM608 64H32C14.33 64 0 78.33 0 96v320c0 17.67 14.33 32 32 32h576c17.67 0 32-14.33 32-32V96c0-17.67-14.33-32-32-32zM48 400v-64c35.35 0 64 28.65 64 64H48zm0-224v-64h64c0 35.35-28.65 64-64 64zm272 192c-53.02 0-96-50.15-96-112 0-61.86 42.98-112 96-112s96 50.14 96 112c0 61.87-43 112-96 112zm272 32h-64c0-35.35 28.65-64 64-64v64zm0-224c-35.35 0-64-28.65-64-64h64v64z" />
                </svg>
              </div>
              <NcDropDown
                data={[
                  { id: "TND", name: "TND", icon: "" },
                  { id: "EUR", name: "EUR", icon: "" },
                  { id: "USD", name: "USD", icon: "" },
                ]}
                renderTrigger={renderTrigger}
                renderItem={renderItem}
                className={`flex-1`}
              />
            </div>
          </form>
          <ButtonPrimary className="w-full" onClick={handleSearchClick}>
            Rechercher
          </ButtonPrimary>
        </>
        <DisplaySideBarBody
          hotel={searchResult!}
          selectedArrangements={selectedArrangements}
          selectedRooms={selectedRooms}
        />
        {searchResult && (
          <>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 "></div>
            <div className="flex justify-between items-center">
              <div className="text-xl font-bold mb-2">Total</div>
              <div className="flex items-end flex-col">
                {calculateTotalPrice().price_without_promotion !==
                  calculateTotalPrice().price && (
                  <div className="text-red-600 text-sm line-through">
                    {calculateTotalPrice().price_without_promotion}{" "}
                    {searchData?.currency}
                  </div>
                )}
                <div className="font-bold text-xl">
                  {calculateTotalPrice().price} {searchData?.currency}
                </div>
              </div>
            </div>
            {!isStopStale && (
              <ButtonPrimary
                className="w-full !bg-green-700"
                onClick={handleInitBooking}
                loading={initBookingService.loading}
              >
                <div className="text-sm">
                  {!isOverBooked ? "Réserver" : "Demande de réservation"}
                </div>
              </ButtonPrimary>
            )}
          </>
        )}
      </div>
    );
  };

  const bookingRooms = () => {
    return (
      <div className="listingSection__wrap lg:border-b border-b-0 p-0 border-none">
        {searchResult?.occupancies?.map((room: any, index: any) => (
          <div key={index}>
            <h2 className="text-primary-blue font-bold capitalize lg:text-xl text-base">
              Chambre {index + 1}
            </h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-5" />
            <BookingRooms
              isHotelDetails
              hotelDetails={hotelDetails}
              selectedRoomType={selectedRooms?.[index]}
              onSelectRoomType={onSelectRoomType(index)}
              roomIndex={index}
              selectedArrangements={selectedArrangements}
              setSelectedArrangements={setSelectedArrangements}
              roomTypes={room.room_types}
            />
          </div>
        ))}
      </div>
    );
  };

  const cityName =
    destinationId &&
    searchDestinations.cities.find((el) => el.id === destinationId)?.name;
  const hotelName =
    hotelSlug &&
    (searchDestinations.hotels.find((el) => el.slug === hotelSlug)?.name ||
      hotelDetails?.name);

  if (hotelDetailsLoading) return <Preloader />;
  if (!hotelDetails) return <Page404 />;

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      <DefaultHeader
        logo={hotelSetting?.logo || ""}
        destinationName={hotelName || cityName || ""}
      />

      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={hotelDetails?.cover}
                loading="lazy"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {hotelDetails?.images
              ?.slice(0, 4)
              .map((item: any, index: number) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? "hidden sm:block" : ""
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item}
                    loading="lazy"
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <BannerImagesIcon />
              <span className="ml-2 text-primary-gray text-sm font-medium">
                Afficher toutes les photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={
            hotelDetails ? [hotelDetails?.cover, ...hotelDetails.images] : []
          }
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="lg:w-[70%]  space-y-8 lg:space-y-10 lg:pr-10 pb-20">
          <StayCardH
            isHotelDetails
            hotel={{ ...searchResult!, data: hotelDetails! }}
          />
          <div id="search-room-result">{searchResult && bookingRooms()}</div>
          {renderSection3()}
          {renderSection2()}
          {renderSection4()}
          {renderHotelRoomsSection()}
        </div>

        {/* SIDEBAR */}
        <div className="lg:w-[30%] hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-3">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && searchResult && (
        <MobileFooterSticky
          handleConfirmButton={handleInitBooking}
          loading={initBookingService.loading}
          selectedDate={searchData.dateRange}
          hotel={searchResult}
          selectedArrangements={selectedArrangements}
          selectedRooms={selectedRooms}
          isOverBooked={isOverBooked}
          isStopStale={isStopStale}
        />
      )}
      <Footer hotelDetails={hotelDetails} />
    </div>
  );
};

export default ListingStayDetailPage;
