import GallerySlider from "components/GallerySlider/GallerySlider";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import SeeMoreText from "components/SeeMoreText";
import { ISelectedArrangements } from "components/StayCardPricesTable/StayCardPricesTable";
import { IRoomType, ISearchResult } from "interfaces/searchResult";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Select from "shared/Select/Select";
import { IHotelDetails } from "interfaces/hotel";
import IconAndText from "components/IconAndText";
import { Fragment, useState } from "react";
import NcModal from "shared/NcModal/NcModal";
import ModalPhotos from "./ModalPhotos";
import { useSearch } from "context/SearchContext";
import AvailabilityBadge from "components/Badge/AvailabilityBadge";
import EquipmentList from "./Equipements";
import moment from "moment";

interface IRoomCard {
  className?: string;
  isStopSales?: boolean;
  room: ISearchResult["occupancies"][0]["room_types"][0];
  calculateRoomPrice?: {
    price: number;
    price_without_promotion: number;
  };
  setSelectedArrangements: (data: ISelectedArrangements) => void;
  selectedArrangements: ISelectedArrangements;
  roomIndex: number;
  isSelected?: boolean;
  onSelectRoomType?: () => void;
  isHotelDetails?: boolean;
  hotelDetails?: IHotelDetails;
  board?: IRoomType["board"];
  badgeDisplay?: Boolean;
}
const defaultImage = "images/default.png";

const currentDate = new Date();

function RoomCard({
  onSelectRoomType = () => {},
  isSelected = false,
  className,
  isStopSales,
  room,
  calculateRoomPrice,
  setSelectedArrangements,
  selectedArrangements,
  roomIndex,
  hotelDetails,
  board,
  badgeDisplay,
}: IRoomCard) {
  const [isSeeMoreModalOpen, setIsSeeMoreModalOpen] = useState(false);
  const [isModalPhotosOpen, setIsModalPhotosOpen] = useState(false);
  const { searchData } = useSearch();

  const roomOverview = hotelDetails?.room_types?.find(
    (el) => el.id == room.data.id
  );

  const renderSliderGallery = () => {
    return (
      <div className="cursor-pointer relative flex-shrink-0 w-full md:w-48 h-56 md:h-56">
        <GallerySlider
          onClickImage={() => {
            setIsModalPhotosOpen(true);
          }}
          ratioClass="aspect-square"
          galleryImgs={
            roomOverview?.cover
              ? [roomOverview.cover, ...(roomOverview.images || [])]
              : [defaultImage, ...(roomOverview?.images || [])]
          }
          uniqueID={`StayCardH_${"testId"}`}
          imageClassName="object-cover w-full h-full"
        />
        {/*
        {(isStopSales || room.quantity <= 0) && (
          <SaleOffBadge desc="Stop sales" className="absolute left-3 top-3" />
        )}
         */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:px-5 flex flex-col space-y-2">
        <div className="space-y-2 flex justify-between">
          <div className="flex items-center space-x-2 py-2 text-base font-semibold ">
            <span> {roomOverview?.name}</span>

            {badgeDisplay && (
              <AvailabilityBadge
                stopSale={isStopSales || false}
                rateType={room.rate_type}
                minStay={1}
              />
            )}
          </div>
        </div>

        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 " />
        {(room.max_pers || room.min_pers) && (
          <div className="text-sm">
            <div className="">Minimum personnes : {room.min_pers}</div>
            <div className="">Maximum personnes : {room.max_pers}</div>
          </div>
        )}

        {/* Cancellation policies */}
        {board && board[0].cancellation_policies.until && (
          <div className="text-xs ">
            {new Date(board[0].cancellation_policies.until).getTime() >
            currentDate.getTime() ? (
              <span className="text-green-700 font-medium">
                Annulation gratuite avant le{" "}
                {moment(board[0].cancellation_policies.until).format(
                  "D MMM YYYY"
                )}
              </span>
            ) : (
              <span className="text-red-600">
                Frais d'annulation {board[0].cancellation_policies.amount} TND{" "}
              </span>
            )}
          </div>
        )}

        {/* Équipements */}
        <EquipmentList equipments={roomOverview?.equipments || []} />

        {/* Description avec SeeMoreText */}
        <div className="text-sm">
          <SeeMoreText
            text={roomOverview?.description || ""}
            numberOfWords={15}
            handleSeeMore={() => setIsSeeMoreModalOpen(true)}
          />
        </div>
        {calculateRoomPrice && (
          <div className="flex flex-col h-full justify-center">
            <Select
              onChange={(e) =>
                setSelectedArrangements({
                  ...selectedArrangements,
                  [roomIndex]: {
                    ...(selectedArrangements[roomIndex] || {}),
                    [room.data.id]: {
                      ...((selectedArrangements[roomIndex] &&
                        selectedArrangements[roomIndex][room.data.id]) ||
                        {}),
                      boardId: Number(e.target.value),
                    },
                  },
                })
              }
              className="w-72 mt-4 mb-2"
            >
              {room.board.map((el, idx) => (
                <option value={el.data.id} key={idx}>
                  {el.data.name}
                  {el.cancellation_policies?.type === "penalty_cancellation" &&
                    ` Annulation payante`}
                </option>
              ))}
            </Select>
          </div>
        )}
      </div>
    );
  };
  return (
    <Fragment>
      <div
        className={` nc-StayCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-primary-gray rounded-2xl overflow-hidden hover:shadow-xl transition-shadow will-change-transform ${className} ${
          isSelected ? "p-2 border-2 border-emerald-700" : ""
        } `}
        data-nc-id="StayCardH"
      >
        <div className="flex flex-col md:flex-row ">
          {renderSliderGallery()}
          <div className="w-full pr-2">{renderContent()}</div>

          <div className=" h-full flex flex-col justify-between mt-5 px-2 pb-2">
            {calculateRoomPrice && (
              <div className="">
                {calculateRoomPrice?.price_without_promotion !==
                  calculateRoomPrice?.price && (
                  <div className=" w-max mx-auto  text-center line-through text-md text-red-600">
                    {calculateRoomPrice?.price_without_promotion}{" "}
                    {searchData?.currency}
                  </div>
                )}

                <div className=" w-max mx-auto  text-lg lg:text-center text-right font-semibold">
                  {calculateRoomPrice?.price} {searchData?.currency}
                </div>
              </div>
            )}
            {calculateRoomPrice && room.quantity > 0 && (
              <div className=" flex justify-end">
                <ButtonPrimary
                  onClick={onSelectRoomType}
                  className={`mt-4 ${isSelected ? "!bg-green-700" : ""} `}
                >
                  <span className="text-sm">
                    Sélectionn{isSelected ? "ée" : "er"}
                  </span>
                </ButtonPrimary>
              </div>
            )}
          </div>
        </div>
      </div>
      <NcModal
        renderTrigger={() => null}
        isOpenProp={isSeeMoreModalOpen}
        onCloseModal={() => setIsSeeMoreModalOpen(false)}
        modalTitle={roomOverview?.name}
        renderContent={() => (
          <div className="space-y-4 items-start">
            <div className="w-full">
              <h1 className="font-bold text-base mb-3">Description :</h1>
              <SeeMoreText
                text={roomOverview?.description || ""}
                numberOfWords={-1}
                handleSeeMore={() => setIsSeeMoreModalOpen(true)}
              />
            </div>

            {/* Trait vertical entre les deux sections */}

            <div className="w-full">
              <h1 className="font-bold mb-3">Equipements :</h1>
              <div className="grid grid-cols-5 gap-4">
                {roomOverview?.equipments?.map((el) => (
                  <IconAndText
                    icon={el.la_icon}
                    name={el.title}
                    key={el.title}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      />
      <ModalPhotos
        imgs={roomOverview?.images || []}
        isOpen={isModalPhotosOpen}
        onClose={() => setIsModalPhotosOpen(false)}
        initFocus={0}
        uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
      />
    </Fragment>
  );
}

export default RoomCard;
